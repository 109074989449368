<template>
    <!--
    <div class="card">
      <div class="card-header border-0 pt-3" style="text-align: center">
        <div class="card-title m-0">Otelcilik</div>
        <div class="d-flex align-items-center py-1">
          <a
            class="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#add_animal_tag_modal"
            id="kt_toolbar_primary_button"
          >
            Test
          </a>
        </div>
      </div>
    </div>
  -->
    <div class="row g-5 gx-xxl-8 mt-3">
      <div class="col-xxl-8">
        <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
          <div>
            <!--
            <div class="card-header border-0">
              <div class="card-title m-0">Modül Detayları</div>
            </div>
            -->
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="card-body">
                <div
                  class="symbol symbol-100px symbol-circle w-45px ms-2 mt-2"
                  style="padding-right: 0px"
                >
                  <img
                  src="/media/images/2432846.png"
                    class="p-1"
                  />
                </div>
                <h1 class="mt-3 mb-6">
                  AI Otel Asistanı Modülü
                  <span class="ms-2 badge-light-success badge me-auto"
                    >Güncel</span
                  >
                </h1>
                <h3>Modül Açıklaması</h3>
                <p>
                  Bu otelcilik modülü, otelcilikle ilgili gelen yorumları yapay zeka modeline ileterek onların değerlendirilmesini amaçlar. Gelen otel yorumlarını yazılı olarak yapay zekaya ileten bu modül, müşteri geri bildirimlerini etkin bir şekilde yönetmeyi sağlar. Yapay zeka algoritmaları, yorumları analiz ederek müşteri memnuniyetini artırmak için önemli içgörüler sunar ve otellerin hizmet kalitesini sürekli olarak iyileştirmelerine yardımcı olur. Bu sayede otelcilik işletmeleri, müşteri deneyimlerini daha etkin bir şekilde yönetebilir ve olumlu geri bildirimlere hızlı bir şekilde yanıt verebilirler.
                </p>
                <h3>Sıkça Sorulan Sorular</h3>
                <div class="border rounded mb-3 mt-4">
                  <div
                    class="card-header align-items-center"
                    role="button"
                    data-bs-toggle="collapse"
                    v-bind:data-bs-target="'#qa_manual' + 1"
                  >
                    <h3 class="card-title align-items-start flex-column">
                      <span class="fw-bolder text-dark"
                        >Uygulama Nasıl Çalışır?</span
                      >
                    </h3>
  
                    <!--end::Content-->
                  </div>
                  <div>
                    <div v-bind:id="'qa_manual' + 1" class="collapse">
                      <!--begin::Card body-->
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="card-body pt-5">
                          <!--begin::Item-->
                          <div class="mb-7 d-flex align-items-sm-center">
                            <!--begin::Title-->
                            <div
                              class="
                                d-flex
                                flex-row-fluid flex-wrap
                                align-items-center
                              "
                            >
                              <div class="flex-grow-1 me-2">
                                <a class="text-gray-800 fw-bold fs-7"
                                  >Bu modülü yüklediğinizde sistem üzerinden giriş yapıp otelinize gelen yorumları yapay zeka modeline iletmeniz yeterlidir. Yapay zeka gerçeğe en yakın oranda değerlendirip cevaplandırır. Yorumların değerlendirilmesini sağlar.   
                                </a>
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                        </div>
                      </div>
                      <!--end::Card body-->
                    </div>
                  </div>
                </div>
  
                <div class="border rounded mb-3 mt-4">
                  <div
                    class="card-header align-items-center"
                    role="button"
                    data-bs-toggle="collapse"
                    v-bind:data-bs-target="'#qa_manual' + 2"
                  >
                    <h3 class="card-title align-items-start flex-column">
                      <span class="fw-bolder text-dark"
                        >Modül Kurulumu Nasıl Yapılır?</span
                      >
                    </h3>
  
                    <!--end::Content-->
                  </div>
                  <div>
                    <div v-bind:id="'qa_manual' + 2" class="collapse">
                      <!--begin::Card body-->
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="card-body pt-5">
                          <!--begin::Item-->
                          <div class="mb-7 d-flex align-items-sm-center">
                            <!--begin::Title-->
                            <div
                              class="
                                d-flex
                                flex-row-fluid flex-wrap
                                align-items-center
                              "
                            >
                              <div class="flex-grow-1 me-2">
                                <a class="text-gray-800 fw-bold fs-7"
                                  >Satın alıp "Yükle" butonuna tıklamanız yeterlidir.
</a>
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                        </div>
                      </div>
                      <!--end::Card body-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4">
        <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
          <div>
            <div class="card-header border-0">
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  fs-6
                  justify-content-center
                  flex-grow-1
                "
              >
                <!--begin::Label-->
                <div class="fw-bold fs-1 text-center">
                  <div class="card-title m-0">Fiyatlandırma</div>
                </div>
                <!--end::Label-->
              </div>
            </div>
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="card-body pt-0">
                <!--begin::Stats-->
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    fs-6
                    justify-content-center
                    flex-grow-1
                    mt-4
                  "
                >
                  <!--begin::Label-->
                  <div class="fw-bold fs-1 text-center">
                    <h1 style="font-size: 32px">Ücretsiz</h1>
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stats-->
  
                <!--begin::Stats-->
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    mb-5
                    mt-auto
                    fs-6
                    justify-content-center
                    flex-grow-1
                  "
                >
                  <!--begin::Label-->
                  <div class="fw-bold text-gray-400 text-center mt-4">
                    Satın al ve kullanmaya başla!
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stats-->
  
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    mt-auto
                    justify-content-center
                    flex-grow-1
                  "
                >
                  <!--begin::Button-->
                  <router-link 
                  to="/hotelManagement"
                  class="btn btn-md btn-success"
                    style="align-items: center; width: 90%">
  
                    Satın Al
                  </router-link>
                  <!--end::Button-->
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
          <div class="card-header border-0">
            <div
              class="
                d-flex
                align-items-center
                flex-wrap
                fs-6
                justify-content-center
                flex-grow-1
              "
            >
              <!--begin::Label-->
              <div class="fw-bold fs-1 text-center">
                <div class="card-title m-0">Ayrıntılar</div>
              </div>
              <!--end::Label-->
            </div>
          </div>
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-3
                      mb-3
                    "
                  >
                    <div class="fs-6 text-gray-800 fw-bolder">Güncelleme</div>
                    <div class="fw-bold text-gray-400">Ömür Boyu</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-3
                      mb-3
                    "
                  >
                    <div class="fs-6 text-gray-800 fw-bolder">Teknik Destek</div>
                    <div class="fw-bold text-gray-400">Ömür Boyu</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-3
                      mb-3
                    "
                  >
                    <div class="fs-6 text-gray-800 fw-bolder">Lisans</div>
                    <div class="fw-bold text-gray-400">Full</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      min-w-125px
                      py-3
                      px-4
                      me-3
                      mb-3
                    "
                  >
                    <div class="fs-6 text-gray-800 fw-bolder">Kurulum</div>
                    <div class="fw-bold text-gray-400">Tek Tıklama</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!--end::Basic info-->
  </template>
  
  <style lang="scss">
  .aside-menu .menu .menu-sub .menu-item a a.menu-link {
    padding-left: calc(0.75rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  
  .aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
    padding-left: calc(1.5rem + 25px);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  </style>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useI18n } from "vue-i18n";
  import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
  import * as Yup from "yup";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import store from "@/presentation/store";
  import SwalNotification from "@/presentation/plugins/SwalNotification";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { UpdateUserModel } from "@/domain/user/updateUser/model/UpdateUserModel";
  
  export default defineComponent({
    name: "Profilim",
    components: { Field, Form, ErrorMessage },
    setup() {
      const { t, te } = useI18n();
      const swalNotification = new SwalNotification();
      const scrollElRef = ref<null | HTMLElement>(null);
      const submitButton = ref<HTMLElement | null>(null);
      const userController = store.state.ControllersModule.userController;
      const updateUserModel = ref<UpdateUserModel>({});
  
      //Create form validation object
      const infoForm = Yup.object().shape({
        name: Yup.string().required(t("validators_error.required")).label("Name"),
        surname: Yup.string()
          .required(t("validators_error.required"))
          .label("Surname"),
        company: Yup.string()
          .required(t("validators_error.required"))
          .label("Company"),
      });
  
      const getUserInfo = () => {
        userController
          .aboutUser()
          .then((response) => {
            if (response.isSuccess) {
              updateUserModel.value.name = response.getValue().name;
              updateUserModel.value.surname = response.getValue().surname;
              updateUserModel.value.tenantName = response.getValue().tenantName;
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      //Form submit function
      const onSubmit = (values) => {
        // Activate loading indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
  
        userController
          .updateUser(updateUserModel.value)
          .then((response) => {
            if (response.isSuccess) {
              submitButton.value?.removeAttribute("data-kt-indicator");
  
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      };
  
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else return text;
      };
  
      onMounted(() => {
        getUserInfo();
        setCurrentPageTitle(t("modals.detail.pageTitle"));
        
        if (scrollElRef.value) {
          scrollElRef.value.scrollTop = 0;
        }
      });
  
      return {
        infoForm,
        PAGE_TITLE_PATH,
        translate,
        onSubmit,
        updateUserModel,
      };
    },
  });
  </script>
  